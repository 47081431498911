import React from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import CookieConsent from "./components/CookieConsent"
import HomePage from "./pages/home/HomePage";
import PolicyPage from "./pages/policy/PolicyPage";
import RefundPage from "./pages/refund/RefundPage";
import TermsPage from "./pages/terms/TermsPage";
import { PAGE_PATHS } from "./constant/paths";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <CookieConsent />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path={PAGE_PATHS.PRIVACY_POLICY} element={<PolicyPage />} />
          <Route path={PAGE_PATHS.REFUND_CANCELLATION} element={<RefundPage />} />
          <Route path={PAGE_PATHS.TERMS} element={<TermsPage />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
