import React, { useEffect, useState } from "react";
import "./policy.css";

import { Navigation } from "../../components/Navigation";
import { ButtonGroup } from "../../components/ButtonGroup";
import { Footer } from "../../components/Footer";
import { Copyright } from "../../components/Copyright";
import { Helmet } from "react-helmet-async";

import JsonData from "../../data/data.json";

function PolicyPage() {
    const [activeButton, setActiveButton] = useState("privacy");
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
        console.log(`${buttonId} button clicked`);
    };

    const [jsonData, setJsonData] = useState({});
    useEffect(() => {
        setJsonData(JsonData);
    }, []);

    return (
        <>
            <Helmet>
                <title>Privacy and Policy - Boost Traffics</title>
                <meta name="description" content="Explore Boost Traffics's Privacy Policy to understand how we collect, use, and protect your data. Learn about your rights and our practices for securing personal information." />
                <meta name="keywords" content="Privacy Policy, Boost Traffics, personal data, data protection, user rights, security practices" />
                <meta name="author" content="Boost Traffics Team" />
            </Helmet>
            <div>
                <Navigation />
                <ButtonGroup activeButton={activeButton} onButtonClick={handleButtonClick} />
                <div id="policy" className="text-left">
                    <div className="container">
                        <div class="title-section">
                            <h2>PRIVACY POLICY</h2>
                            <p>Last updated 29 November 2024</p>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>Personal Statement</h2>
                            </div>
                            <div class="right-section">
                                <p>
                                    Boost Traffic (hereafter referred to as "Boost Traffic", "we," "our," or "us") value and respect the privacy of our customers. This Privacy Policy explains how we collect, use, store, and protect your personal information when you visit our website or use our services. By accessing our website and services, you consent to the practices described in this policy.
                                </p>
                            </div>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>1. Information We Collect</h2>
                            </div>
                            <div class="right-section">
                                <p>
                                    We collect personal information from users in several ways, including but not limited to:
                                </p>
                                <ul>
                                    <li>
                                        <strong>Personal Information:</strong> Name, email address, phone number, billing information, and any other data provided by you when signing up, making a purchase, or communicating with our official communication channels such as WhatsApp, Facebook Messenger, Instagram Direct, and any other communication channels we may use.
                                    </li>
                                    <li>
                                        <strong>Website Usage Data:</strong> IP address, browser type, device details, pages visited, and time spent on the website.
                                    </li>
                                    <li>
                                        <strong>Service-Related Information:</strong> Keywords, website URLs, and other information you provide related to SEO and traffic enhancement services.
                                    </li>
                                    <li>
                                        <strong>Third-Party Platform Communications:</strong> Information shared through platforms like WhatsApp, Facebook Messenger, Instagram Direct, or any other official communication channels, such as names, contact details, transaction information, and service-related queries.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>2. How We Use Your Information</h2>
                            </div>
                            <div class="right-section">
                                <p>We may use the collected information for the following purposes:</p>

                                <ul>
                                    <li>
                                        <strong>To Provide Our Services:</strong> Delivering SEO and traffic enhancement services tailored to your needs.
                                    </li>
                                    <li>
                                        <strong>To Communicate with You:</strong> Sending follow-up messages via email, WhatsApp, Facebook Messenger, Instagram Direct, or any other communication channels about product promotions, updates, or requested information.
                                    </li>
                                    <li>
                                        <strong>To Process Transactions:</strong> Handling billing, invoicing, and payments based on communications through any supported platform.
                                    </li>
                                    <li>
                                        <strong>For Marketing:</strong> Sending occasional marketing messages via email, WhatsApp, Facebook Messenger, Instagram Direct, or any other platform about new services, offers, or industry insights. You can opt-out anytime.
                                    </li>
                                    <li>
                                        <strong>To Comply with Legal Obligations:</strong> As required by law, such as responding to legal requests or protecting our rights.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>3. Sharing Your Information</h2>
                            </div>
                            <div class="right-section">
                                <p>
                                    We do not sell or rent your personal information. However, we may share your information in the following circumstances:
                                </p>
                                <ul>
                                    <li>
                                        <strong>With Third-Party Service Providers:</strong> To help us deliver services (e.g., analytics, payment processors, email service providers).
                                    </li>
                                    <li>
                                        <strong>Legal Requirements:</strong>  When required to do so by law or in response to legal processes.
                                    </li>
                                    <li>
                                        <strong>With Your Consent:</strong>  If we need to share your information for any additional services, we will ask for your explicit consent.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>4. Cookies & Tracking</h2>
                            </div>
                            <div class="right-section">
                                <p>
                                    We use cookies and similar technologies to enhance your experience and analyze site traffic. Cookies help us:
                                </p>

                                <ul>
                                    <li>
                                        Personalize your browsing experience.
                                    </li>
                                    <li>
                                        Identify your account linked to marketing efforts.
                                    </li>
                                    <li>
                                        Track the source of your website visits and interactions.
                                    </li>
                                </ul>
                                <p>
                                    You can manage or disable cookies through your browser settings. However, please note that disabling cookies may limit some website functionalities.
                                </p>
                            </div>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>5. User Rights</h2>
                            </div>
                            <div class="right-section">
                                <p>
                                    Under the Personal Data Protection Act (PDPA), you have the following rights regarding your personal data:
                                </p>

                                <ul>
                                    <li>
                                        <strong>Access and Correction:</strong> You have the right to request access to and correct any personal data we hold about you.
                                    </li>
                                    <li>
                                        <strong>Data Deletion:</strong> You may request that we delete your personal information.
                                    </li>
                                    <li>
                                        <strong>Withdrawal of Consent:</strong>  You can withdraw consent for receiving promotional emails at any time by clicking the "unsubscribe" link in the email or by contacting us directly.
                                    </li>
                                </ul>
                                <p>
                                    To exercise any of these rights, please contact us at &nbsp;
                                    <a
                                        href={`mailto:${process.env.REACT_APP_EMAIL_LINK}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="Send an email to boosttraffic.official@gmail.com">
                                        boosttraffic.official@gmail.com
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>6. Data Security</h2>
                            </div>
                            <div class="right-section">
                                <p>
                                    We implement reasonable and industry-standard security measures to protect your personal information, including encryption and secure server technologies. However, no system is completely secure, and we cannot guarantee the absolute security of your data.
                                </p>
                            </div>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>7. Testimonials</h2>
                            </div>
                            <div class="right-section">
                                <p>
                                    Any testimonials displayed on our website are with the explicit consent of the individuals. We will not post or share any testimonial without prior approval from the person providing it.
                                </p>
                            </div>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>8. Third-Party Links</h2>
                            </div>
                            <div class="right-section">
                                <p>
                                    Our website may contain links to third-party websites that are not operated by us. We are not responsible for the privacy practices or content of these external sites. We recommend reviewing the privacy policies of any third-party sites before sharing your personal information.
                                </p>
                            </div>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>9. Updates to This Privacy Policy</h2>
                            </div>
                            <div class="right-section">
                                <p>
                                    We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page with an updated "Last updated Date" noted at the top. Please review this policy periodically to stay informed about how we protect your information.
                                </p>
                            </div>
                        </div>
                        <div class="policy-section">
                            <div class="left-section">
                                <h2>10. Contact Us</h2>
                            </div>
                            <div class="right-section">
                                <p>
                                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                                </p>
                                <ul class="no-bullets">
                                    <li>
                                        <strong>Boost Traffic</strong>
                                    </li>
                                    <li>
                                        <a
                                            href={`mailto:${process.env.REACT_APP_EMAIL_LINK}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Send an email to boosttraffic.official@gmail.com">
                                            Email: boosttraffic.official@gmail.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+601125519178"> Phone: +6011-2551 9178</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Copyright />
            </div>
        </>
    );
}

export default PolicyPage;
